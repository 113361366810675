import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import StyledComponent from 'styled-components';

import Colors from '../../constant/colors';
import AppText from '../../constant/text';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  question: {
    color: Colors.form.popupDialog.question.color
  },
  okayBtn: {
    color: Colors.form.popupDialog.okayBtn.color,
    backgroundColor: Colors.form.popupDialog.okayBtn.color
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography align='center' classes={{ h4: classes.question }} variant="h4">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 'auto',
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function TooltipDialog(props)
{
  let imgPath = '';
  if(props.tooltip && props.tooltip.question && props.tooltip.question.toLowerCase().indexOf('birthday') !== -1)
  {
    imgPath = 'icon-birthday.png';
  }
  else if(props.tooltip && props.tooltip.question && props.tooltip.question.toLowerCase().indexOf('faith') !== -1)
  {
    imgPath = 'logo-sm.png';
  }
  return (
      <Dialog
        onClose={() => props.onClose()}
        aria-labelledby="question"
        open={props.tooltip ? props.tooltip.isOpen : false}
      >
      {imgPath.length > 0 &&
      <img
        src={require('../../asset/' + imgPath)}
        className='popup-dialog-icon'
      />}
        <DialogContent>
          <DialogTitle
            id="question"
            onClose={props.onClose}
          >{props.tooltip ? props.tooltip.question : ''}</DialogTitle>
          <Typography
            gutterBottom
            variant='subtitle1'
            align='center'
          >{props.tooltip && props.tooltip.message ? props.tooltip.message.split('\n').map((item, key) =>
          {
            return (
              <span key={key}>
                {item}
                <br/>
              </span>
            )
          }) : ''}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => props.onClose()}
            color="primary"
            size="large"
            variant="contained"
          >{AppText.register.popupDialog.okayBtn}</Button>
        </DialogActions>
      </Dialog>
  );
}

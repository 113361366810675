import ApiRequest    from '../../api/request.js';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableFilter from './EnhancedTableFilter';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import FormValidated    from '../../components/Form/FormValidated';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import StyledComponent from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TransitionModal from '../../components/TransitionModal';

/**
  @param  {Bool}  isPrintAvailable  If print button displays
  @param  {Bool}  isCsvAvailable    If CSV export button displays
*/
class EnhancedTable extends React.Component
{
  // MARK: - Data fields
  // Forms displayed by row exits
  _updateForm = null;
  _createForm = null;
  // Flag to control if form inputs changed and we need to setup state again
  _updateFormInputsChanged = false;
  _createFormInputsChanged = false;
  // Once we update form inputs after they were modified manually by parent
  // we need to be able to put them in our state and flag to
  // shouldComponentUpdate that there is no need to recheck
  _skipCheckingCreateFormInputs = false;

  // Styled component using CSS from backend
  _css = null;


  // MARK; - Constructor
  constructor(props)
  {
    super(props);

    // Add submit button to update form
    var updateFormInputs = [...props.updateFormInputs];
    if(updateFormInputs)
    {
      updateFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Update Record', onClick: this.updateFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
    }

    // Add submit button to create form
    var createFormInputs = [...props.createFormInputs];
    if(createFormInputs)
    {
      createFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Create Record', onClick: this.createFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
    }

    // Need to modify update form inputs so copy to state
    // But first add on submit button to form
    this.state =
    {
      order: 'asc',
      orderBy: props.defaultSort,
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 25,

      updateModalIsVisible: false,
      updateFormInputs: updateFormInputs,

      createModalIsVisible: false,
      createFormInputs: createFormInputs,

      filters: new Map(),
    };

    this._updateForm = React.createRef();
    this._createForm = React.createRef();

    // Style component
    this._css = this.styleComponent(props.siteManager);
  }

  // MARK: Accessors
  getStateFor = (id) =>
  {
    return this.state[id];
  }

  getSelected = () =>
  {
    return this.state.selected;
  }

  clearSelected = () =>
  {
    this.setState({ selected: [] });
  }

  clearAll = () =>
  {
    // TODO: Clear filters as well
    this.setState({ selected: [], page: 0 });
  }


  // MARK: - Helpers
  formatDate = (date) =>
  {
    let dt = new Date(date);
    return(`${
    (dt.getMonth()+1).toString().padStart(2, '0')}/${
    dt.getDate().toString().padStart(2, '0')}/${
    dt.getFullYear().toString().padStart(4, '0')} ${
    dt.getHours().toString().padStart(2, '0')}:${
    dt.getMinutes().toString().padStart(2, '0')}:${
    dt.getSeconds().toString().padStart(2, '0')}`);
  }


  // MARK: - Sorting
  handleRequestSort = (event, property) =>
  {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({ order: (isDesc ? 'asc' : 'desc'), orderBy: property });
  };

  stableSort = (array, cmp) =>
  {
    if(array !== null)
    {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) =>
      {
        const order = cmp(a[0], b[0]);
        if (order !== 0)
        {
          return order;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    else
    {
      return false;
    }
  }

  compare = (lhs, rhs, orderBy) =>
  {
    let rhsValue = this.extractValueFromPointer(orderBy, rhs);
    let lhsValue = this.extractValueFromPointer(orderBy, lhs);
    if (rhsValue < lhsValue)
    {
      return -1;
    }
    if (rhsValue > lhsValue)
    {
      return 1;
    }
    return 0;
  }

  getSorting = (order, orderBy) =>
  {
    if(order === null)
    {
      return false;
    }
    return order === 'desc' ? (a, b) => this.compare(a, b, orderBy) : (a, b) => -this.compare(a, b, orderBy);
  }

  handleSelectAllClick = event =>
  {
    if (event.target.checked)
    {
      const newSelecteds = this.props.data.map(row => row._id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  tableRowOnClick = (event, id) =>
  {
    if(this.props.tableRowOnClick)
    {
      this.props.tableRowOnClick(id);
    }
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected = [];

    if(this.props.multiSelectEnabled)
    {
      if (selectedIndex === -1)
      {
        newSelected = newSelected.concat(this.state.selected, id);
      }
      else if (selectedIndex === 0)
      {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      }
      else if (selectedIndex === this.state.selected.length - 1)
      {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      }
      else if (selectedIndex > 0)
      {
        newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex),
                                        this.state.selected.slice(selectedIndex + 1));
      }
    }
    else
    {
      newSelected = (selectedIndex === -1) ? [id] : [];
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) =>
  {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) =>
  {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  isSelected = (id) =>
  {
    var isSelected = (this.state.selected.indexOf(id) !== -1);
    return (this.state.selected.indexOf(id) !== -1);
  }

  extractValueFromPointer = (iFieldName, iRow) =>
  {
    //console.log(iFieldName);
    var fieldName = iFieldName;
    var fieldNameInPtr = "";
    var row = iRow;

    // Get total pointers in key
    let occurrences = (fieldName.match(/\./g) || []).length;
    if(occurrences === 0)
    {
      return iRow[iFieldName];
    }

    // Iterate all pointers
    var splitIndex = -1;
    for(var i = 0; i < occurrences; i++)
    {
      splitIndex = fieldName.indexOf('.');
      try
      {
        // Extract pointer
        fieldNameInPtr  = fieldName.substring(splitIndex + 1);
        fieldName = fieldName.substring(0, splitIndex);

        // Slowly parse down the data
        if(fieldNameInPtr.indexOf('.') !== -1)
        {
          row = row[fieldName];
        }
        else
        {
          return row[fieldName][fieldNameInPtr].toString();
        }
        fieldName = fieldNameInPtr;
      }
      catch(err)
      {
        console.log("Pointer: " + fieldNameInPtr + " not found in property " + fieldName)
        return "";
      }
    }
  }

  updateRecordForField = (iFieldName, iRow) =>
  {
    var visibleText = "";
    var fieldName = iFieldName;
    var fieldNameInPtr = "";
    var row = iRow;

    // Get total pointers in key
    let occurrences = (fieldName.match(/\./g) || []).length
    if(occurrences === 0)
    {
      row[fieldName] = this.state[fieldName];
      return row;
    }

    // Iterate all pointers
    var splitIndex = -1;
    for(var i = 0; i < occurrences; i++)
    {
      splitIndex = fieldName.indexOf('.');
      try
      {
        // Extract pointer
        fieldNameInPtr  = fieldName.substring(splitIndex + 1);
        fieldName = fieldName.substring(0, splitIndex);

        // Slowly parse down the data
        if(fieldNameInPtr.indexOf('.') !== -1)
        {
          row = row[fieldName];
        }
        else
        {
          row[fieldName][fieldNameInPtr] = this.state[iFieldName.substr(iFieldName.lastIndexOf('.') + 1)];
          return row;
        }
        fieldName = fieldNameInPtr;
      }
      catch(err)
      {
        //console.log("Pointer: " + fieldNameInPtr + " not found in property " + fieldName)
        return row;
      }
    }
  }

  /**
  state.selected only contains unique ID's for selected rows and not the actual indices.
  This is a helper function to convert selected ID's to actual table data at the rows of the unique ID's.

    @param  {JSON}  param     Parameters
         -  {Bool}  isForCsv  If data is for csv
  */
  getTableDataForSelectedRows = (params) =>
  {
    try
    {
      var allFormattedData = [];
      var selectedRecord = null;
      var headerId = "";
      var ptrData = "";

      // Iterate all selected IDs
      for(var i = 0; i < this.state.selected.length; i++)
      {
        var formattedData = {};

        // Find record for this ID
        selectedRecord = this.props.data.find(record => record._id === this.state.selected[i]);

        // If formatting for CSV export we need to pad data
        if(params && params.isForCsv)
        {
          // Iterate table headers
          for(var j = 0; j < this.props.headers.length; j++)
          {
            headerId = this.props.headers[j].id;
            ptrData = this.extractValueFromPointer(headerId, selectedRecord);
            if(typeof ptrData === 'object' && ptrData !== null)
            {
              // Build formatted string
              let formattedString = '';
              for(let k = 0; k < ptrData.length; k++)
              {
                let ptrDataKeys = Object.keys(ptrData[k]);
                for(let l = 0; l < ptrDataKeys.length; l++)
                {
                  formattedString += ptrData[k][ptrDataKeys[l]];
                  if(l != ptrDataKeys.length - 1)
                  {
                    formattedString += ':';
                  }
                }
                if(k != ptrData.length - 1)
                {
                  formattedString += ',';
                }
              }
              //ptrData = JSON.stringify(ptrData, undefined, 2);
              ptrData = formattedString;
            }
            if(ptrData !== null && ptrData !== undefined)
            {
              console.log('Header ID: ' + headerId);
              formattedData[this.props.headers[j].label] = ptrData.toString().replace(/<br\/>/gi, ' | ');
            }
            else
            {
              formattedData[this.props.headers[j].label] = '';
            }
          }
        } // Otherwise just send JSON object
        else
        {
          formattedData = selectedRecord;
        }
        // Add to list
        allFormattedData.push(formattedData);
      }

      return allFormattedData;
    }
    catch(err)
    {
      console.log(err);
      console.log(params);
      this.props.showAlert(true, 'Un-oh', err.toString(), 'danger');
      return [];
    }
  }

  tableDidFinishLoading = (action, message, error) =>
  {
    this.props.tableDidFinishLoading(action, message, error);
  }

  /**
    When button action finishes we notify delegate and then delegate invokes this method
    passing in table data and this will return the data

    Didn't want to make the tableData part of the state here
    and didn't want to make the parent responsible for button logic
  */
  handleAction = (action, tableData) =>
  {
    switch(action.type)
    {
      case 'create':
        var inserted = false;
        // Add to table
        var modifiedData = [...tableData];

        // No data
        if(modifiedData === null)
        {
          modifiedData = [action.data];
          inserted = true;
        }
        else if(modifiedData.length === 0)
        {
          modifiedData.push(action.data);
          inserted = true;
        }

        // Find position
        var lhs = -1;
        var rhs = -1;
        var found = false;
        var i = 0;
        while(!found && i < modifiedData.length && !inserted)
        {
          lhs = this.compare(modifiedData[i], action.data, this.state.orderBy);

          if(lhs <= 0) // New first element
          {
            modifiedData.splice(i, 0, action.data);
            found = true;
          }
          else
          {
            i++;
          }
        }
        if(!found && !inserted)
        {
          modifiedData.push(action.data);
        }
        return modifiedData;

      case 'delete':

        // Get selected ID's
        var selected = this.getSelected();
        this.clearSelected();

        // Remove from table
        var modifiedData = [...tableData];
        let length = Array.isArray(selected) ? selected.length : 1;
        var selectedRowIdx = -1;
        for(var i = 0; i < length; i++)
        {
          selectedRowIdx = modifiedData.map( (data) => { return data['_id']; }).indexOf((Array.isArray(selected) ? selected[i] : selected));
          if(selectedRowIdx !== -1)
          {
            console.log('Table removing ' + selectedRowIdx + ' ' + selected);
            modifiedData.splice(selectedRowIdx, 1);
          }
          else
          {
            console.log('Table.handleAction(' + action.type + ') Couldn\'t find index to remove');
          }
        }
        return modifiedData;

      case 'update':
        // Update table data with modified record
        var modifiedData = [...tableData];
        var modifiedIndex = modifiedData.map( (record) => { return record['_id']; }).indexOf(action.data._id);
        var updatedRecord = modifiedData[modifiedIndex];
        console.log(updatedRecord);

        // Iterate update form and update our table with new data
        for(var i = 0; i < this.state.updateFormInputs.length - 1; i++)
        {
          if(this.state.updateFormInputs[i].managedUpdateForm)
          {
            updatedRecord = this.updateRecordForField(this.state.updateFormInputs[i].key ? this.state.updateFormInputs[i].key : this.state.updateFormInputs[i].id, updatedRecord);
          }
        }
        modifiedData[modifiedIndex] = updatedRecord;
        return modifiedData;

      default:
        return tableData;
      }
  }

  // MARK: - Edit button related
  // Close modal
  updateModalOnClose = () =>
  {
    this.setState({ updateModalIsVisible: false });
  }

  // Show modal
  updateButtonOnClick = () =>
  {
    console.log("Table.updateButtonOnClick()");
		var selectedRecord = this.props.data.find(record => record._id === this.state.selected[0]);
		var updateFormInputs = this.state.updateFormInputs;
    var key = "";

    console.log(updateFormInputs);

    // Iterate inputs (skipping submit button)
    for(var i = 0; i < updateFormInputs.length - 1; i++)
    {
      // Allow caller to overwrite the key if needed
      // Useful for SELECT element where ID is text we want to display
      // and key is where the value is located
      key = updateFormInputs[i].key ? updateFormInputs[i].key : updateFormInputs[i].id;

      // If reference field we want to give them the pointer value and let the FormValiateds
      // option list handle displaying the proper text based off keyInReference
      // Make sure this is a reference field first too
      if(updateFormInputs[i].type === 'select' && updateFormInputs[i].reference)
      {
        key = updateFormInputs[i].id + '._id';
      }
      updateFormInputs[i].value = this.extractValueFromPointer(key, selectedRecord);

      if(this.props.model === 'configuration' && ['true', 'false'].includes(updateFormInputs[i].value)) {
        updateFormInputs[i].type = 'boolean';
        updateFormInputs[i].element = 'select';
        updateFormInputs[i].options = [{text: 'True', value: 'true'}, {text: 'False', value: 'false'}];
      }
    }
    console.log(updateFormInputs);
    this.setState({ updateModalIsVisible: true, updateFormInputs: updateFormInputs });
  }

  // The actual form
  updateForm()
  {
    if(this.state.updateModalIsVisible)
    {
      console.log('Table.updateForm()\n' + JSON.stringify(this.state.updateFormInputs, null, 2));
      return(
  		<div align="left" className="left-aligned">
  			<FormValidated
  				ref={this._updateForm}
  				formInputs={this.state.updateFormInputs}
  				formOnChange={this.updateFormOnChange}
  				showErrorList={false}
  				validateOnInit={true}
          siteManager={this.props.siteManager}
  			/>
  		</div>);
    }
    //console.log('Table.updateForm not showing');
  }

  // Handle modify form on change
  updateFormOnChange = (change, isFormValid) =>
	{
    console.log("OnChange " + change.id.substr(change.id.lastIndexOf('.') + 1) + " set to " + change.value);
		this.setState({ [change.id.substr(change.id.lastIndexOf('.') + 1)]: change.value });
	}

  // Send updated form to backend
  updateFormOnSubmit = async(evt) =>
  {
    evt.preventDefault();

    console.log('Table.updateFormOnSubmit()');
    this.props.tableDidStartLoading();

    //console.log(this.state);
    // Build form data
    const formData = new FormData();
    var fieldId = null;
    for(var i = 0; i < this.state.updateFormInputs.length; i++)
    {
      // Don't process submit button
      if(this.state.updateFormInputs[i].type !== 'submit')
      {
        fieldId = this.state.updateFormInputs[i].id.substr(this.state.updateFormInputs[i].id.lastIndexOf('.') + 1);
        if(this.state.updateFormInputs[i].type === 'file')
        {
          formData.append(fieldId, this.state[fieldId]);
        }
        else
        {
          // Skip buttons
          if(this.state.updateFormInputs[i].type !== 'submit')
          {
            formData.set(fieldId, this.state[fieldId]);
          }
        }
      }
    }

    // Send over ID of selected row
    formData.set('id',        this.state.selected[0]);
    formData.set('model',     this.props.model);

    try
    {
      var action =
      {
          type: 'update',
          data: null
      };

      let response = await ApiRequest.sendRequest("post", formData, "data/update", this.props.cookies.get('token'), 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
      console.log("Table.updateForm response:");
      console.log(response.data);
      // Success
      if(response.data.error === null)
      {
        action.data = response.data.results;

        // Notify parent
        this.props.tableDidFinishLoading(action, response.data.message, null);

        this.setState({ updateModalIsVisible: false });
        // TODO: I think because of the conditional rendering and hiding of the form we no longer
        // need this check/call
        if(this._updateForm.current !== null)
        {
          this._updateForm.current.reset();
        }
      }
      // Failed
      else
      {
        this.props.tableDidFinishLoading(action, null, response.data.error);
      }
    }
    catch(err)
    {
      this.props.tableDidFinishLoading(action, null, 'An error has occurred, please try again or contact support.\nError: ' + err);
    }
  }



  // MARK: - Create button related
  // Close modal
  createModalOnClose = () =>
  {
    this.setState({ createModalIsVisible: false });
  }

  // Show modal
  createButtonOnClick = () =>
  {
    console.log("Table.createButtonOnClick()");
    this.setState({ createModalIsVisible: true });
  }

  // The actual form
  createForm()
  {
    if(this.state.createModalIsVisible)
    {
      //console.log('Table.createForm()\n' + JSON.stringify(this.state.createFormInputs, null, 2));
      return(
  		<div align="left" className="left-aligned">
  			<FormValidated
  				ref={this._createForm}
  				formInputs={this.state.createFormInputs}
  				formOnChange={this.createFormOnChange}
  				showErrorList={false}
  				validateOnInit={false}
          siteManager={this.props.siteManager}
  			/>
  		</div>);
    }
    //console.log('Table.createForm not showing');
  }

  // Handle create form on change
  createFormOnChange = (change, isFormValid) =>
	{
		this.setState({ [change.id.substr(change.id.lastIndexOf('.') + 1)]: change.value });
	}

  // Send updated form to backend
  createFormOnSubmit = async(evt) =>
  {
    evt.preventDefault();

    console.log('Table.createFormOnSubmit()');
    this.props.tableDidStartLoading();

    // Build form data
    const formData = new FormData();
    var fieldId = null;
    for(var i = 0; i < this.state.createFormInputs.length; i++)
    {
      fieldId = this.state.createFormInputs[i].id.substr(this.state.createFormInputs[i].id.lastIndexOf('.') + 1);
      if(this.state.createFormInputs[i].type === 'file')
      {
        formData.append(fieldId, this.state[fieldId]);
      }
      else
      {
        // Skip buttons
        if(this.state.createFormInputs[i].type !== 'submit')
        {
          // Array
          if(this.state.createFormInputs[i].multiSelectEnabled)
          {
            let value = (this.state[fieldId] === undefined ? '_empty_array_' : this.state[fieldId]);
            console.log(value);
            formData.set(fieldId, value);
          } // Single value
          else
          {
            formData.set(fieldId, this.state[fieldId]);
          }
        }
      }
    }
    // Send over ID of selected row
    formData.set('model', this.props.model);
    try
    {
      var action =
      {
        type: 'create',
        data: null
      };

      let response = await ApiRequest.sendRequest("post", formData, "data/create", this.props.cookies.get('token'));
      console.log(response.data);
      // Success
      if(response.data.error === null)
      {
        action.data = response.data.results;

        // Notify parent
        this.props.tableDidFinishLoading(action, response.data.message, null);

        // Hide and reset form
        this.setState({ createModalIsVisible: false });

        // TODO: I think because of the conditional rendering and hiding of the form we no longer
        // need this check/call
        if(this._createForm.current !== null)
        {
          this._createForm.current.reset();
        }
      }
      // Failed
      else
      {
        this.props.tableDidFinishLoading(action, null, response.data.error);
      }
    }
    catch(err)
    {
      this.props.tableDidFinishLoading(action, null, 'An error has occurred, please try again or contact support.\nError: ' + err);
    }
  }

  checkInputsForNewKey = (oldInputs, newInputs, formType) =>
  {
    var newInputFound = false;
    var keys = Object.keys(newInputs);
    for(var i = 0; i < keys.length; i++)
    {
      try
      {
        if(!oldInputs[i] || oldInputs[i].id !== newInputs[i].id)
        {
          console.log('Setting update flag for ' + formType + ' form input: ' + newInputs[i].id);
          newInputFound = true;
          break;
        }
      }
      catch(err)
      {
        return false;
      }
    }

    // Check old inputs to make sure all exist in new inputs now
    keys = Object.keys(oldInputs);
    for(var i = 0; i < keys.length; i++)
    {
      try
      {
        if((!newInputs[i] || newInputs[i].id !== oldInputs[i].id) && oldInputs[i].type !== 'submit')
        {
          console.log('Setting update flag for ' + formType + ' form input: ' + oldInputs[i].id);
          newInputFound = true;
          break;
        }
      }
      catch(err)
      {
        return false;
      }
    }

    return newInputFound;
  }

  // MARK: - Filter related
  filterOnChange = (id, evt) =>
  {
    var filters = this.state.filters;
    if(evt.target.value.length > 0)
    {
      filters.set(id, evt.target.value.toLowerCase());
    }
    else
    {
      filters.delete(id);
    }

    this.setState({ filters: filters });
  }

  /**
    Check if filters have changed
    @param  {Map} oldFilters Filters pre update
    @param  {Map} newFilters  Filters post update
  */
  didFiltersChange = (oldFilters, newFilters) =>
  {
    return (JSON.stringify(oldFilters) === JSON.stringify(newFilters))
  }



  // MARK: - Render
  componentDidUpdate()
  {
    console.log('Table.componentDidUpdate()');

    // Form inputs can be manually changed by parent after we are instantiated
    // handle checking for change here so we avoid an infinite update loop by doing it in shouldComponentUpdate
    if(this._updateFormInputsChanged && this._createFormInputsChanged)
    {
      this._updateFormInputsChanged = false;
      const updateFormInputs = [...this.props.updateFormInputs];
      if(updateFormInputs)
      {
        updateFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Update Record', onClick: this.updateFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
      }
      this._createFormInputsChanged = false;
      var createFormInputs = [...this.props.createFormInputs];
      if(createFormInputs)
      {
        createFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Create Record', onClick: this.createFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
      }
      console.log('Table.componentDidUpdate create & update form inputs changed\nAnd are now being set in our state and good to go\nthis._createFormInputsChanged=' + this._createFormInputsChanged + '\nthis._updateFormInputsChanged=' + this._updateFormInputsChanged);
      this.setState({ updateFormInputs: updateFormInputs, createFormInputs: createFormInputs });
    }
    else if(this._updateFormInputsChanged)
    {
      this._updateFormInputsChanged = false;
      var updateFormInputs = [...this.props.updateFormInputs];
      if(updateFormInputs)
      {
        updateFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Update Record', onClick: this.updateFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
      }
      console.log('Table.componentDidUpdate update form inputs changed\nAnd are now being set in our state and good to go\nthis._updateFormInputsChanged=' + this._updateFormInputsChanged);
      this.setState({ updateFormInputs: updateFormInputs });
    }
    else if(this._createFormInputsChanged)
    {
      this._createFormInputsChanged = false;
      var createFormInputs = [...this.props.createFormInputs];
      if(createFormInputs)
      {
        createFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Create Record', onClick: this.createFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
      }
      console.log('Table.componentDidUpdate create form inputs changed\nAnd are now being set in our state and good to go\nthis._createFormInputsChanged=' + this._createFormInputsChanged);
      this.setState({ createFormInputs: createFormInputs });
    }
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    console.log('Table.shouldComponentUpdate()');
    // If style manager provided now, update css
    if(nextProps.siteManager !== null && this.props.siteManager === null)
    {
      this._css = this.styleComponent(nextProps.siteManager);
    }

    if(!this._updateFormInputsChanged)
    {
      this._updateFormInputsChanged = this.checkInputsForNewKey(this.state.updateFormInputs, nextProps.updateFormInputs, 'update');
    }

    if(!this._createFormInputsChanged)
    {
      this._createFormInputsChanged = this.checkInputsForNewKey(this.state.createFormInputs, nextProps.createFormInputs, 'create');
    }

    var shouldUpdate = (this.props.data !== nextProps.data ||
            this.props.headers !== nextProps.headers ||
            this.props.model !== nextProps.model ||
            this.props.selectAllEnabled !== nextProps.selectAllEnabled ||
            this.props.multiSelectEnabled !== nextProps.multiSelectEnabled ||
            this.props.defaultSort !== nextProps.defaultSort ||
            this.props.sortEnabled !== nextProps.sortEnabled ||
            this.props.title !== nextProps.title ||
            this.props.isDeleteAvailable !== nextProps.isDeleteAvailable ||
            this.props.isUpdateAvailable !== nextProps.isUpdateAvailable ||
            this.props.updateFormInputs !== nextProps.updateFormInputs ||
            this.props.isCreateAvailable !== nextProps.isCreateAvailable ||
            this.props.createFormInputs !== nextProps.createFormInputs ||
            this.props.siteManager !== nextProps.siteManager ||
            this.state.order !== nextState.order ||
            this.state.orderBy !== nextState.orderBy ||
            this.state.selected !== nextState.selected ||
            this.state.page !== nextState.page ||
            this.state.dense !== nextState.dense ||
            this.state.rowsPerPage !== nextState.rowsPerPage ||
            this.state.updateModalIsVisible !== nextState.updateModalIsVisible ||
            this.state.updateFormInputs !== nextState.updateFormInputs ||
            this.state.createModalIsVisible !== nextState.createModalIsVisible ||
            this.state.createFormInputs !== nextState.createFormInputs ||
            this.didFiltersChange(this.state.filters, nextState.filters)
        );
      console.log('Table.shouldComponentUpdate(' + shouldUpdate + ')');
      return shouldUpdate;
  }

  render()
  {
    console.log('Table.Render()');
    console.log(this.state.createFormInputs);
    var emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, (this.props.data ? this.props.data.length : 0) - this.state.page * this.state.rowsPerPage);
    return (
      <this._css>
        <Paper className='table-inner'>
          <EnhancedTableFilter
            filters={this.props.headers ? this.props.headers.filter(header => header.filter) : []}
            onChange={this.filterOnChange}
            disabled={this.state.selected.length !== 0}
          />
          <EnhancedTableToolbar
            isPrintAvailable={this.props.isPrintAvailable}
            isCsvAvailable={this.props.isCsvAvailable}
            isDeleteAvailable={this.props.isDeleteAvailable}

            isUpdateAvailable={this.props.isUpdateAvailable}
            updateButtonOnClick={this.updateButtonOnClick}

            isCreateAvailable={this.props.isCreateAvailable}
            createButtonOnClick={this.createButtonOnClick}

            customButton1={this.props.customButton1 ? this.props.customButton1() : null}

            cookies={this.props.cookies}
            tableDidStartLoading={this.props.tableDidStartLoading}
            tableDidFinishLoading={(action, message, error) => this.tableDidFinishLoading(action, message, error)}
            selected={this.state.selected}
            title={this.props.title}
            model={this.props.model}
            getTableDataForSelectedRows={this.getTableDataForSelectedRows}

            siteManager={this.props.siteManager}
          />
          <div className='table-wrapper'>
            <Table
              className='table'
              aria-labelledby="tableTitle"
              size={this.state.dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={this.state.selected.length}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.props.data ? this.props.data.length : 0}
                headers={this.props.headers}
                selectAllEnabled={this.props.selectAllEnabled}
                sortEnabled={(this.props.sortEnabled !== undefined) ? this.props.sortEnabled : true}
              />
              <TableBody>
                {this.props.data && this.stableSort(this.props.data, this.getSorting(this.state.order, this.state.orderBy))
                  .filter( (row) => // Apply filter
                  {
                    var isHiddenByFilter = false;
                    var rowValue = null;

                    let itr = this.state.filters[Symbol.iterator]();
                    var filter = itr.next();
                    while(filter.value)
                    {
                      rowValue = this.extractValueFromPointer(filter.value[0], row);

                      // Find header for filter so we can check if isObject
                      let header = null;
                      for(let i = 0; i < this.props.headers.length; i++)
                      {
                        if(filter.value[0] === this.props.headers[i].id)
                        {
                          header = this.props.headers[i];
                          break;
                        }
                      }

                      let isInvalidNumber = false;
                      try
                      {
                        isInvalidNumber = isNaN(filter.value[1].replace('<', '').replace('>', ''));
                      }
                      catch(err)
                      {
                        isInvalidNumber = false;
                      }

                      // Validate text
                      if(isInvalidNumber || header.isObject)
                      {
                        console.log(JSON.stringify(rowValue).toLowerCase());
                        console.log(filter.value[1]);

                        // Date/time
                        if(filter.value[0] === 'time' || filter.value[0] === 'createdOn' || filter.value[0] === 'pClass.time')
                        {
                          if(this.formatDate(rowValue).toLowerCase().indexOf(filter.value[1]) === -1)
                          {
                            isHiddenByFilter = true;
                            break;
                          }
                        }
                        // Not an object
                        else if(!rowValue || (!header.isObject && rowValue.toLowerCase().indexOf(filter.value[1]) === -1))
                        {
                          isHiddenByFilter = true;
                          break;
                        }
                        // Object
                        else if(!rowValue || (header.isObject && JSON.stringify(rowValue).toLowerCase().indexOf(filter.value[1]) === -1))
                        {
                          isHiddenByFilter = true;
                          break;
                        }
                      } // Validate number
                      else
                      {
                        if(filter.value[1].indexOf('>') !== -1)
                        {
                          if(filter.value[1].replace('>', '') > rowValue)
                          {
                            isHiddenByFilter = true;
                            break;
                          }
                        }
                        else if(filter.value[1].indexOf('<') !== -1)
                        {
                          if(filter.value[1].replace('<', '') < rowValue)
                          {
                            isHiddenByFilter = true;
                            break;
                          }
                        }
                        else
                        {
                          if(rowValue != filter.value[1])
                          {
                            isHiddenByFilter = true;
                            break;
                          }
                        }
                      }

                      filter = itr.next();
                    }
                    return !isHiddenByFilter;
                  })
                  .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row, rowIndex) =>
                  {
                    const isItemSelected = this.isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${rowIndex}`
                    var tableCells = null;
                    if(row)
                    {
                      // Iterate header columns and output rows of data
                      tableCells = this.props.headers.map((header, headerIndex) =>
                      {
                        //console.log(row);
                        var visibleTextFieldName = header.id; // Header ID is the field in our data we want to display
                        var visibleText = "";

                        // Photo
                        if(header.id.indexOf('photo') !== -1 || header.id.indexOf('member.imageURL') !== -1)
                        {
                          let src = this.extractValueFromPointer(visibleTextFieldName, row);
                          visibleText = (src ? <div className="image-cropper"><img src={src} alt='Profile image' className='profile-pic' /></div> : '')
                        }
                        else
                        {
                          visibleText = this.extractValueFromPointer(visibleTextFieldName, row);
                        }
                        // First column
                        if(headerIndex === 0)
                        {
                          let temp = (visibleTextFieldName === 'time' || visibleTextFieldName === 'createdOn' || visibleTextFieldName === 'pClass.time') ? this.formatDate(visibleText) : (visibleText ? visibleText.toString() : '');
                          return <TableCell component="th" key={rowIndex + '-' + headerIndex} id={labelId} scope="row" padding="none">{temp}</TableCell>;
                        }
                        else // Additional columns
                        {
                          var temp = visibleText;
                          try
                          {
                            temp = visibleText.split(/<br\/>/).join("");
                          }
                          catch(err){}

                          // Tinme field
                          if(visibleTextFieldName === 'time' || visibleTextFieldName === 'createdOn' || visibleTextFieldName === 'pClass.time')
                          {
                            return <TableCell key={rowIndex + '-' + headerIndex} align="left">{this.formatDate(temp)}</TableCell>;
                          }
                          else if(header.isObject)
                          {
                            console.log('Is object');
                            return <TableCell key={rowIndex + '-' + headerIndex} align="left"><pre>{temp !== undefined ? JSON.stringify(temp, null, 4) : ''}</pre></TableCell>;
                          }

                          return <TableCell key={rowIndex + '-' + headerIndex} align="left">{temp !== undefined ? temp.toString() : ''}</TableCell>;
                        }
                      });
                    }


                    return (
                      <TableRow
                        hover
                        onClick={event => this.tableRowOnClick(event, row._id)}
                        role="checkbox"
                        aria-checked={this.isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={this.isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            className="table-checkbox"
                          />
                        </TableCell>
                        {tableCells}
                      </TableRow>
                    );
                  })}
                {this.emptyRows > 0 && (
                  <TableRow style={{ height: (this.state.dense ? 33 : 53) * this.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={this.props.data ? this.props.data.length : 0}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <TransitionModal
  				isOpen={this.state.updateModalIsVisible}
  				onClose={this.updateModalOnClose}
  				modalContent={this.updateForm()}
  			/>
        <TransitionModal
  				isOpen={this.state.createModalIsVisible}
  				onClose={this.createModalOnClose}
  				modalContent={this.createForm()}
  			/>
      </this._css>
    );
  }

  // Style component
  styleComponent = (siteManager) =>
  {
    console.log("Table.styleComponent()");

    var checkboxSelectedBg = '#F50057';
    if(siteManager !== null)
    {
      checkboxSelectedBg = siteManager.getColorFor('Table', 'Checkbox (Row Selected)');
    }

    return StyledComponent.div`

      .table-inner
      {
        width: '100%';
        margin-bottom: 0;
      }
      .table-wrapper
      {
        overflow-x: auto;
        width: 100%;
        display: block;
      }
      .table
      {
        min-width: 750;
      }
      .MuiCheckbox-colorSecondary.Mui-checked
      {
        color: ${checkboxSelectedBg} !important;
      }

      width: '100%';
      margin-top: 0;
      padding: 24;
     `;
  }
}/*
<TransitionModal
  isOpen={this.state.updateModalIsVisible}
  onClose={this.updateModalOnClose}
  title={`Modifying record: ${(this.state.selected ? this.state.selected[0] : '')}`}
  description='Fill out the form below to update this record'
  modalContent={this.updateForm()}
/>
*/


export default EnhancedTable;

import { NavList } from '../../components/Nav';
import React 		 from 'react';
import { Redirect }  from 'react-router-dom';
import DataManager from '../../components/DataManager/DataManager';

/**
  *  State:
  *  	@param 	{JSON} 		admin		Contains nav links and icons
  */
export const Admin = ({ cookies,
                        selectedNavItem,
                        siteManager,
                        showAlert,
                        updateMasterState,
                        sideNavComponent }) =>
{
  console.log('Selected: ' + selectedNavItem);
  const user = cookies.get('user');
  return(
  <div>
    {!user ? <Redirect to='/login' /> : ''}
    {user && (user.authorization.type !== 'admin' && user.authorization.type !== 'customer') ? <Redirect to='/logout' /> : ''}
    {selectedNavItem !== -1 &&
    <DataManager
      model={selectedNavItem ? selectedNavItem : ''}
      showAlert={showAlert}
      cookies={cookies}
      siteManager={siteManager}
    />}
  </div>);
}

export default Admin;

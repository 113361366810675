import React from 'react';

export default function FormFooter(props)
{
  return (
    <div className="form-footer">
			<h3>{props.title}</h3>
		</div>
  );
}

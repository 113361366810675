// Helmet: Dynamic page title
import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Emails, Home, Reports } from './pages';
import { NoMatch } from './pages/NoMatch';
import {schemaFieldToFormInput} from './components/Form/utility';
import {PageController} from './components/page';
import ModelManager from './pages/ModelManager';
import JobManager from './pages/jobManager';
// import { Layout } from './components/Layout';
import { NavList, NavigationBar } from './components/Nav';
import StyledComponent from 'styled-components';
import { Footer } from './components/footer';
import { withCookies } from 'react-cookie';
import { toast } from "react-toastify";
import AlertDismissible from './components/AlertDismissible';

import './css/App.css';
import './css/bootstrap.min.css';
import './css/themify-icons.css';
import 'react-toastify/dist/ReactToastify.css';

import { ApiManager, SiteManager }from './managers';

class App extends Component
{
  _pageController = null;
  _showTitleInNav = true;
  _css = null;

  // MARK: - Constructor
  constructor(props)
  {
    console.log("App()");
    super(props);
    this.state =
    {
      alert:
      {
          isShowing:      false,
          header:         '',
          detail:         '',
          dismissText:    '',
      },
      siteManager: null,
      title: '',
      pageComponents: null,
      path: window.location.pathname,
      redirect: false,
      windowSize: window.innerWidth,
      selectedNavItem: -1,
      sideNavComponent: { navData: [] },
      apiManager: null,
    }

    this._pageController = React.createRef();

    this._css = this.styleComponent(props.siteManager);
    toast.configure();

    window.addEventListener('resize', () =>
    {
      this.setState({ windowSize: window.innerWidth });
    });
  }

  // On first launch fetch all styles from backend and Initialize websocket
  // because this is async, render() will be called before this completes
  async componentWillMount()
  {
    // Initiate API manager
    const token = this.props.cookies.get('token');
    const apiManager = ApiManager.Init(token);
    this.setState({ apiManager: apiManager });

    // Initialize site manager
    let siteManager = await SiteManager.GetInstance(this.props.cookies);

    // Build page/form input map
    const pageComponents = new Map();
    const pageNames = siteManager.getPageNames();
    for(let i = 0; i < pageNames.length; i++)
    {
      const formInputs = [];
      const page = siteManager.getPageData(pageNames[i]);

      // Iterate components in page and pre-process them
      for(let i = 0; i < page.components.length; i++)
      {
        // Handle form
        if(page.components[i].type === 'form')
        {
          // Now iterate schema fields and convert to form inputs
          for(let j = 0; j < page.components[i].form.length; j++)
          {
            const formInput = await schemaFieldToFormInput(page.components[i].form[j], token);
            formInputs.push(formInput);
          }

          page.components[i].form = formInputs;
        }
      }

      pageComponents.set(page.name, page.components);
    }

    this.setState(
    {
      siteManager: siteManager,
      title: siteManager.getFrontendTitle(),
      pageComponents: pageComponents,
    });
  }


// MARK: - Helpers
/**
   Alert box that can be dismissed
   @param   {bool}      show            To show/hide alert
   @param 	{string} 	header 			Title text
   @param 	{string}	detail 			Description text
   @param 	{string}	dismissText 	Dismiss button text
   @param   {string}    variant         Variant type { 'primary','secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark' }
  */
  showAlert = (show,
              header = '',
              detail = '',
              variant = 'success',
              dismissText = 'Dismiss') =>
  {
    this.setState({ alert:
    {
      isShowing:      show,
      header:         header.toString(),
      detail:         typeof detail === 'object' ? JSON.stringify(detail) : detail.toString(),
      variant:        variant,
      dismissText:    dismissText
    }});
  }

  updateMasterState = (state, cb = null) =>
  {
    this.setState(state, () =>
    {
      if(cb !== null)
      {
        cb();
      }
    });
  }

  // MARK: - Page  controller related
  reloadPageData = () =>
  {
    this.forceUpdate();
  }

  forward = (forwardUrl) =>
  {
    this.setState({ redirect: forwardUrl });
  }

  // MARK; - Render
  shouldComponentUpdate(nextProps, nextState)
  {
    return (this.state.title !== nextState.title ||
        this.state.pageComponents !== nextState.pageComponents ||
        this.state.alert.isShowing !== nextState.alert.isShowing ||
        this.state.path !== nextState.path ||
        this.state.redirect !== nextState.redirect ||
        this.state.selectedNavItem !== nextState.selectedNavItem ||
        this.state.apiManager !== nextState.apiManager
    );
  }

  componentDidUpdate()
  {
    if(this.state.redirect)
    {
      this.setState({ redirect: false });
    }
    //console.log(this.state.path + " == " + window.location.pathname);
    if(this.state.path !== window.location.pathname)
    {
      this.setState({ path: window.location.pathname });
    }
  }

  render()
  {
    let components = this.state.pageComponents !== null ? this.state.pageComponents.get('home') : [];
    let sideNavComponent = null;
    if(components)
    {
      components = [...components];
      for(let i = 0; i < components.length; i++)
      {
        if(components[i].type === 'side-nav')
        {
          sideNavComponent = components[i];
          break;
        }
      }
    }


    let alertDismissible;
    if(this.state.alert.isShowing)
    {
      alertDismissible = <AlertDismissible header={this.state.alert.header}
       detail={this.state.alert.detail}
       dismissText={this.state.alert.dismissText}
       show={this.state.alert.isShowing}
       variant={this.state.alert.variant}
       dismissCb={this.showAlert}/>;
    }

    const isMobile = this.state.windowSize <= 768;
    const token = this.props.cookies.get('token');

    return (
      <this._css>
        <Helmet>
          <title>{this.state.title}</title>
        </Helmet>
        <Router>
          <div className='app-wrapper'>
            {this.state.siteManager &&
            <NavigationBar
              title={this.state.title}
              selectedNavItem={this.state.selectedNavItem}
              showTitle={this._showTitleInNav}
              showAlert={this.showAlert}
              loggedIn={token !== undefined}
              siteManager={this.state.siteManager}
              path={this.state.path.replace('/', '')}
              updateMasterState={this.updateMasterState}
              forward={this.forward}
              cookies={this.props.cookies}
              isMobile={isMobile}
            />}
            {alertDismissible}
            <div className="pane-holder">
              {!isMobile &&
                token &&
              <div className="left-pane">
                {this.state.siteManager &&
                <NavList
                  active={this.state.selectedNavItem}
                  siteManager={this.state.siteManager}
                  user={this.props.cookies.get('user')}
                  showAlert={this.showAlert}
                  updateMasterState={this.updateMasterState}
                />}
              </div>}
              <div className={`center-pane center-pane-${this.props.cookies.get('token') ? '80' : '100'}`}>
                <Switch>
                  <Route exact path="/" render={(props) =>
                    <PageController {...props}
                      ref={this._pageController}
                      showAlert={this.showAlert}
                      path={this.state.path.replace('/', '')}
                      updateMasterState={this.updateMasterState}
                      cookies={this.props.cookies}
                      reloadPageData={this.reloadPageData}
                      siteManager={this.state.siteManager}
                      pageComponents={this.state.pageComponents !== null ? [...this.state.pageComponents.get('login')] : []}
                    />}
                  />
                  <Route path="/login" render={(props) =>
                    <PageController {...props}
                      ref={this._pageController}
                      showAlert={this.showAlert}
                      path={this.state.path.replace('/', '')}
                      updateMasterState={this.updateMasterState}
                      cookies={this.props.cookies}
                      reloadPageData={this.reloadPageData}
                      siteManager={this.state.siteManager}
                      pageComponents={this.state.pageComponents !== null ? [...this.state.pageComponents.get('login')] : []}
                    />}
                  />
                  <Route path="/model-manager" render={(props) =>
                    <ModelManager {...props}
                      showAlert={this.showAlert}
                      cookies={this.props.cookies}
                      siteManager={this.state.siteManager}
                    />}
                  />
                  <Route path="/jobs" render={(props) =>
                    <JobManager {...props}
                      showAlert={this.showAlert}
                      cookies={this.props.cookies}
                      siteManager={this.state.siteManager}
                    />}
                  />
                  <Route path="/reports" render={(props) =>
                    this.state.apiManager &&
                    <Reports {...props}
                      showAlert={this.showAlert}
                      cookies={this.props.cookies}
                      siteManager={this.state.siteManager}
                    />}
                  />
                  <Route path="/emails" render={(props) =>
                    this.state.siteManager &&
                    <Emails
                      cookies={this.props.cookies}
                      showAlert={this.showAlert}
                      siteManager={this.state.siteManager}
                      updateMasterState={this.updateMasterState}
                    />}
                  />
                  {false &&
                  <Route path="/register" render={(props) =>
                    <PageController {...props}
                      ref={this._pageController}
                      showAlert={this.showAlert}
                      path={this.state.path.replace('/', '')}
                      updateMasterState={this.updateMasterState}
                      cookies={this.props.cookies}
                      reloadPageData={this.reloadPageData}
                      siteManager={this.state.siteManager}
                      pageComponents={this.state.pageComponents !== null ? [...this.state.pageComponents.get('register')] : []}
                    />}
                  />}
                  <Route path="/home" render={(props) =>
                  <>
                    {this.state.pageComponents &&
                    <Home {...props}
                      ref={this._pageController}
                      showAlert={this.showAlert}
                      selectedNavItem={this.state.selectedNavItem}
                      cookies={this.props.cookies}
                      siteManager={this.state.siteManager}
                      pageComponents={components}
                      updateMasterState={this.updateMasterState}
                      isMobile={this.state.windowSize <= 768}
                    />}
                    {!this.state.pageComponents && <></>}
                    </>
                  }
                  />
                  <Route path="/logout" render={(props) =>
                  {
                    this.props.cookies.remove('user');
                		this.props.cookies.remove('token');
                    this.setState({ title: this.state.siteManager ? this.state.siteManager.getFrontendTitle() : '' }, () =>
                    {
                      props.history.push('/login');
                    });
                  }}
                  />
                  <Route path="/reset" render={(props) =>
                    <PageController {...props}
                      ref={this._pageController}
                      showAlert={this.showAlert}
                      path={this.state.path.replace('/', '')}
                      updateMasterState={this.updateMasterState}
                      cookies={this.props.cookies}
                      reloadPageData={this.reloadPageData}
                      siteManager={this.state.siteManager}
                      pageComponents={this.state.pageComponents !== null ? [...this.state.pageComponents.get('reset')] : []}
                    />}
                  />
                  <Route path="/confirm-reset" render={(props) =>
                    <PageController {...props}
                      ref={this._pageController}
                      showAlert={this.showAlert}
                      path={this.state.path.replace('/', '')}
                      updateMasterState={this.updateMasterState}
                      cookies={this.props.cookies}
                      reloadPageData={this.reloadPageData}
                      siteManager={this.state.siteManager}
                      pageComponents={this.state.pageComponents !== null ? [...this.state.pageComponents.get('confirm-reset')] : []}
                    />}
                  />
                  <Route component={NoMatch} />
                </Switch>
              </div>
            </div>
            {this.state.redirect &&
            <Redirect to={{ pathname: this.state.redirect }} />}
          </div>
        </Router>
        <Footer
          copyrightText={'Copyright 2023 Check In'}
          siteManager={this.state.siteManager}
        />
      </this._css>
    );
  }


  styleComponent = () =>
	{
		return StyledComponent.div`
      .app-wrapper
      {
        min-height: 100%;
        height: auto !important;
        height: 100%;
        background: #F5F5F5;
      }

      .pane-holder
      {
          margin-top: 0px;
          max-width:  100%;
          margin-top: 10px;
          display:    flex;
          flex-direction: row;
          padding-bottom: 80px;
          @media only screen and (max-width: 992px)
    			{
            flex-direction: column;
          }
      }
      .left-pane
      {
          text-align: left;
          display: flex;
          flex-direction: column;
          @media only screen and (min-width: 993px)
          {
            width: 15%;
            float: left;
            margin-left: 1%;
          }
          @media only screen and (max-width: 992px)
          {
            width: 100%;
          }
      }

      .center-pane
      {
          display:flex;
          margin-left: 2%;
          margin-right: 2%;
          flex-direction:column;
          float: right;
          border-radius: 4px;

          @media only screen and (max-width: 992px) and (min-width: 601px)
          {
            margin-top: 2%;
            margin-left: auto;
            margin-right: auto;
          }
          @media only screen and (max-width: 600px)
          {
            width:100% !important;
            margin-left: 0%;
            margin-right: 0%;
          }
      }
      .center-pane-80
      {
        width: 80%;
      }
      .center-pane-100
      {
        width: 100%;
      }

      .full-width:
      {
        width: 100% !important;
      }

      text-align:         center;
      background-color:   #FFFFFF;
      width:              100%;
      position:           absolute !important;
      top:                0px;
      bottom:             0;

      @media only screen and (max-width: 992px)
      {
        position: relative;
      }
		`;
	}
}

/**
  withCookies will inject the cookies object as a prop into App.
  We can then access this.props.cookies within App
*/
export default withCookies(App);

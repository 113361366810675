import React 		 from 'react';
import { Redirect }  from 'react-router-dom';
import StyledComponent from 'styled-components';
import Admin from './admin';
import { Colors } from '../../constant';


export default class Home extends React.Component
{
	_isMounted = false;
	_css = null;

	// MARK: - Constructor
	constructor(props)
	{
		console.log('Home()');
		super(props);

		this.state =
		{
			redirect: false
		};

		this._css = this.styleComponent(props.siteManager);
	}

	async componentDidMount()
	{
		console.log('Home.componentDidMount()');
		this._isMounted = true;
	}



	// MARK: - Render
	shouldComponentUpdate(nextProps, nextState)
	{
		return (this.props.selectedNavItem !== nextProps.selectedNavItem ||
								this.props.siteManager !== nextProps.siteManager ||
												this.state.redirect !== nextState.redirect);
	}

	componentDidUpdate()
  {
    if(this.state.redirect)
    {
      console.log('Home.clearing redirect');
      this.setState({ redirect: false });
    }
  }



	render()
	{
		console.log('Home.render()');
		const user = this.props.cookies.get('user');
		return (
			<this._css>
				{this.state.redirect &&
				<Redirect to={{ pathname: this.state.redirect }} />}
				{!user ? <Redirect to='/login' /> : ''}
				{user &&
					(user.authorization.type === 'admin' || user.authorization.type === 'customer') &&
				<Admin
					cookies={this.props.cookies}
					selectedNavItem={this.props.selectedNavItem}
					siteManager={this.props.siteManager}
					showAlert={this.props.showAlert}
					updateMasterState={(state) => this.setState(state)}
				/>}
			</this._css>
		);
	}


	styleComponent = () =>
	{
		return StyledComponent.div`
			.settings-close-btn
			{
				position: 'absolute',
		    right: 8px;
		    top: 8px;
		    color: ${Colors.settings.closeButton.color}
			}

			margin-top: 10px;
		`;
	}
}

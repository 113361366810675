
const monthsOfYearLong = [ 'January',  'February',  'March',  'April',  'May',  'June',  'July',  'August',  'September',  'October',  'November',  'December' ];
const monthsOfYearShort = [ 'Jan',  'Feb',  'Mar',  'Apr',  'May',  'June',  'July',  'Aug',  'Sep',  'Oct',  'Nov',  'Dec' ];
export function formatAMPM(date)
{
  date = new Date(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'P.M.' : 'A.M.';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function formatDateOnly(date)
{
  date = new Date(date);
  var day = date.getDate();
  // This prints it 16.07.2020
  //var month = ("0" + (date.getMonth() + 1)).slice(-2);
  // This prints it July 16th, 2020
  var month = monthsOfYearShort[(date.getMonth())];
  var year = date.getFullYear();
  var strTime = month + ' ' + day + ' ' + year;
  return strTime;
}

export function formatFullDate(date)
{
  date = new Date(date);
  const dateStr = formatDateOnly(date);
  const timeStr = formatAMPM(date);
  return dateStr + ' ' + timeStr;
}

/**
  Get the minutes apart from two date times
  @param  {Date}  fromDate
  @param  {Date}  toDate
  @returns  {Int} Total minutes apart
*/
export function getMinutesApart(fromDate, toDate)
{
  var diff =(toDate.getTime() - fromDate.getTime()) / 1000;
  diff /= 60;
  return Math.round(diff);
}


export default {
  formatFullDate: formatFullDate,
  formatAMPM: formatAMPM,
  formatDateOnly: formatDateOnly,
  getMinutesApart: getMinutesApart
};

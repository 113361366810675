import React from 'react';
import StyledComponent from 'styled-components';
import Link from '@material-ui/core/Link';
import TooltipDialog from '../Form/tooltipDialog';
import AppText from '../../constant/text';

export default class AuthHeader extends React.Component
{
  _css = null;

  constructor(props)
  {
     super(props);
     this.state =
     {
       tooltip:
       {
         isOpen: false,
         question: AppText.register.statementOfFaith.title,
         message: AppText.register.statementOfFaith.body
       }
     };
     this._css = this.styleComponent(props.siteManager);
   }

   render()
   {
     return (<this._css>
       <TooltipDialog
         tooltip={this.state.tooltip}
         onClose={() =>
         {
           console.log('On close');
           const tooltip = {...this.state.tooltip};
           tooltip.isOpen = false;
           this.setState({ tooltip: tooltip });
         }}
       />
       <img
         src={require('../../asset/' + this.props.imageSrc)}
       />
       <h3>{this.props.header}</h3>
       <h4>{this.props.detail}</h4>
       <Link
         component="button"
         variant="button"
         color="primary"
         onClick={() =>
         {
           const tooltip = {...this.state.tooltip};
           tooltip.isOpen = true;
           this.setState({ tooltip: tooltip });
         }}
       >{AppText.register.statementOfFaith.button}</Link>
     </this._css>);
   }

   styleComponent = (siteManager) =>
   {
     return StyledComponent.div`
       img
       {
         width: 360px;
         display: block;
         margin: 0 auto;
         text-align: center;
         margin-bottom: 10px;
       }
       h3, h4
       {
         text-align: center;
         color: #235373;
       }
     `;
   }
}

import ApiRequest    from '../api/request.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormLoader    from '../components/Form/FormLoader';
import React 		 from 'react';
import StyledComponent from 'styled-components';
import { Datetime } from '../utils';


// TODO: Make schema fields read from backend so we can centralize changes to this page
export default class ModelManager extends React.Component
{
	// MARK: - Data fields
	_isMounted = false;
  _css = null;
  _loadDataInterval = null;
	_minutes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60];
  _hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
	_refreshRate = 5000;

  // MARK: - Constructor
	constructor(props)
	{
		super(props);

		this.state =
		{
			jobs: [],
			isLoading: false,
			jobName: 'Members',
      repeatHours: 0,
			repeatMinutes: 0,
			repeatSeconds: 0,
			repeat: false,
			usingBatchPreset: null,
		};

		this._css = this.styleComponent();
		this._schemaForm = React.createRef();

    console.log('JobManager()');
	}

	componentDidMount()
	{
		console.log('JobManager.componentDidMount()');
		this._isMounted = true;
		this._loadDataInterval = setInterval( () => this.loadData(), this._refreshRate);
	}

	componentWillUnmount()
  {
		console.log('JobManager.componentWillUnmount()');
    clearInterval(this._loadDataInterval);
  }


	// MARK: - APIs
	loadData = async() =>
	{
		console.log('JobManager.loadData()');
		//this.setState({ isLoading: true });

		var params = { params: {} };

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "classy/jobs", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				//this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);
			this.setState({
				//isLoading: false,
				jobs: response.data.results,
				usingBatchPreset: response.data.usingBatchPreset,
			});

		}
		catch(err)
		{
			//this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

  addJob = async() =>
	{
		if(window.confirm("Are you sure you want to manually run a job? It is recommended to use the suggested batch schedule by selecting the checkbox above.")) {

			console.log('JobManager.addJob()');
			this.setState({ isLoading: true });

			var params =
			{
				jobName: this.state.jobName,
				repeat: this.state.repeat,
				repeatHours: this.state.repeatHours,
				repeatMinutes: this.state.repeatMinutes,
				repeatSeconds: this.state.repeatSeconds,
			};

			try
			{
				var response = await ApiRequest.sendRequest("post", params, "classy/start-job", this.props.cookies.get('token'));
				if(response.data.error !== null)
				{
					this.setState({ isLoading: false });
					this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
					return;
				}

				console.log(response.data);

				const jobs = [...this.state.jobs];
				jobs.unshift(response.data.results);
				this.setState({
					isLoading: false,
					jobs: jobs
				});

			}
			catch(err)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}
	}

  clearJobs = async() =>
	{
		console.log('JobManager.clearJobs()');
		this.setState({ isLoading: true });

		var params = { };

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "classy/clear-jobs", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);
			this.setState({
				isLoading: false,
				jobs: response.data.results
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	cancelJob = async(jobKey) =>
	{
		console.log('JobManager.cancelJob()');
		this.setState({ isLoading: true });

		var params =
		{
			jobKey: jobKey
		};

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "classy/cancel-job", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			console.log(response.data.results);
			this.setState({
				isLoading: false,
				jobs: response.data.results
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}

	setUsingBatchPreset = async() =>
	{
		console.log('JobManager.setUsingBatchPreset()');
		this.setState({ isLoading: true });

		var params =
		{
			usingBatchPreset: !this.state.usingBatchPreset
		};

		try
		{
			var response = await ApiRequest.sendRequest("post", params, "classy/use-batch-preset", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				return;
			}

			this.setState({
				isLoading: false,
				usingBatchPreset: !this.state.usingBatchPreset,
			});
		}
		catch(err)
		{
			this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}


	// MARK: - Render
  render()
  {
    console.log('JobManager.render()');

    return(
			<Paper>
			<this._css>
        <link rel="stylesheet" href="https://www.herokucdn.com/purple3/latest/purple3.min.css" />
				<FormLoader isLoading={this.state.isLoading}/>
	      <Grid container spacing={1} style={{width: '100%'}}>
            <Grid item xs={12}>
							<DialogTitle disableTypography>
		            <Typography
		              variant="h3"
		              classes={{h3: 'friends-title'}}
		              display='block'
		              align={'center'}
		            >{'Batch Job Manager'}</Typography>
		          </DialogTitle>

							<Grid item style={{marginTop: '25px'}}>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
								 	Calculate Total Raised - Iterate all transactions in Classy and calculate the total raised
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Import Members - Import members from Classy and update amounts raised on existing members (Goal amount is updated in Registration Types job)
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Import Teams - Import teams from Classy
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Update Answers - Update the size question answer for all members with missing response
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Update Teams - Fix any member records where team was not imported yet when member was imported. Also update total raised for team.
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Registration Types - Update member's goal amount to honor registration type overrides
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									All - Will run each batch in order of: import members, import teams, update teams, registration types, calculate totals. DOES NOT invoke the 'Send emails' job. So only new members imported are emailed.
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Send emails - Sends the QR code email TO EVERY MEMBER IN AN EVENT
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Members and teams template - Uses the newly optimized job to import members and teams
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Tickets and answers template - Uses the newly optimized job to import tickets and answers
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									calculate totals raised and update teams template - Uses the newly optimized job to calculate totals
								</Typography>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									Heroku instance manager - Checks if event day and scales up Heroku dynos. If day after event, scales down.
								</Typography>
							</Grid>

							<Grid item style={{marginTop: '25px'}}>
								<Typography gutterBottom variant="subtitle1" component="h2" className={'job-description'}>
									You cannot schedule two jobs to repeat at the same intervals
								</Typography>

								{this.state.usingBatchPreset !== null && 
								<FormControlLabel
									control={
										<Checkbox
											checked={this.state.usingBatchPreset}
											onChange={(e) => this.setUsingBatchPreset()}
											name="repeatCheckBox"
											color="primary"
											disabled={this.state.isLoading}
										/>
									}
									classes={{label: 'checkbox-label'}}
									label="Use suggested batch schedule"
								/>}
							</Grid>

							{this.state.usingBatchPreset !== null && 
							<Grid item xs={12} style={{marginTop: '25px'}}>
								<FormControl className={'select-control'}>
									<InputLabel id="job-select-label">Job Type</InputLabel>
									<Select
										labelId="job-select-label"
										id="job-select"
										value={this.state.jobName}
										onChange={(e) => this.setState({ jobName: e.target.value })}
									>
										<MenuItem value={'calculate-totals'}>Calculate Total Raised</MenuItem>
										<MenuItem value={'members'}>Import Members</MenuItem>
										<MenuItem value={'teams'}>Import Teams</MenuItem>
										<MenuItem value={'update-answers'}>Update Answers</MenuItem>
										<MenuItem value={'update-teams'}>Update Teams</MenuItem>
										<MenuItem value={'tickets'}>Registration Types</MenuItem>
										<MenuItem value={'send-emails'}>Send emails</MenuItem>
										<MenuItem value={'event-day-members-teams'}>Members and teams template</MenuItem>
										<MenuItem value={'event-day-tickets-answers'}>Tickets and answers template</MenuItem>
										<MenuItem value={'event-day-totals-update-teams'}>Calculate total raised and update teams template</MenuItem>
										<MenuItem value={'check-event-day'}>Heroku instance manager</MenuItem>
										<MenuItem value={'all'}>All</MenuItem>
									</Select>
								</FormControl>
							</Grid>}

							{this.state.usingBatchPreset !== null && 
							<Grid item xs={12} style={{marginTop: '25px'}}>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.state.repeat}
											onChange={(e) => this.setState({ repeat: e.target.checked })}
											name="repeatCheckBox"
											color="primary"
										/>
									}
									classes={{label: 'checkbox-label'}}
									label="Repeat"
								/>
							</Grid>}

							{this.state.repeat &&
							<>
              <Grid item xs={12} style={{marginTop: '25px'}}>
								<FormControl className={'select-control'}>
									<InputLabel id="repeat-hours-label">Every (N) Hours: </InputLabel>
									<Select
										labelId="repeat-hours-label"
										id="hours-select"
										value={this.state.repeatHours}
										onChange={(e) => this.setState({ repeatHours: e.target.value })}
									>
										{this._hours.map( (hour, i) =>
										{
											return (<MenuItem value={hour}>{hour}</MenuItem>)
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} style={{marginTop: '25px'}}>
								<FormControl className={'select-control'}>
									<InputLabel id="repeat-minutes-label">Every (N) Minutes: </InputLabel>
									<Select
										labelId="repeat-minutes-label"
										id="minutes-select"
										value={this.state.repeatMinutes}
										onChange={(e) => this.setState({ repeatMinutes: e.target.value })}
									>
										{this._minutes.map( (minute, i) =>
										{
											return (<MenuItem value={minute}>{minute}</MenuItem>)
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} style={{marginTop: '25px'}}>
								<FormControl className={'select-control'}>
									<InputLabel id="repeat-seconds-label">Every (N) Seconds: </InputLabel>
									<Select
										labelId="repeat-seconds-label"
										id="seconds-select"
										value={this.state.repeatSeconds}
										onChange={(e) => this.setState({ repeatSeconds: e.target.value })}
									>
										{this._minutes.map( (second, i) =>
										{
											return (<MenuItem value={second}>{second}</MenuItem>)
										})}
									</Select>
								</FormControl>
							</Grid>
							</>}

							{this.state.usingBatchPreset === null ? (
								<p>Loading...</p>
							) : (
							<Grid item xs={12} style={{marginTop: '25px'}}>
                <Button
				          color="primary"
				          startIcon={<AddIcon />}
									onClick={ () => this.addJob() }
				       	>Add</Button>
                <Button
				          color="primary"
				          startIcon={<ClearIcon />}
									onClick={ () => this.clearJobs() }
				       	>Clear Inactive and Completed Jobs</Button>
              </Grid>)}
            </Grid>

						{this.state.jobs.map( (job, i) =>
            {
              let bgColor = 'bg-light-purple';
              let progress = job.progress;
              if(job.state === 'completed')
              {
                bgColor = 'bg-purple';
                progress = 100;
              }
              else if(job.state === 'failed')
              {
                bgColor = 'bg-dark-red';
                progress = 100;
              }

							let startedOn = job.startedOn ? Datetime.formatFullDate(new Date(job.startedOn)) : 'Not started yet';
							let finishedOn = job.finishedOn ? Datetime.formatFullDate(new Date(job.finishedOn)) : 'TBD';
							let runtime = job.finishedOn ? Math.abs((new Date(job.finishedOn).getTime() - new Date(job.startedOn).getTime()) / 1000) + ' seconds': '';

              return(
								<Grid item xs={12} style={{marginTop: '25px'}}>
                  <div className="flex justify-between mb2">
                    <div className='mt2 mb1'><span className="hk-label">Job ID:</span>{`${job.data.jobName}-${job.id}`}</div>
                    <div className='mt2 mb1'><span className="hk-label">State:</span>{job.state}</div>
                  </div>
                  <div className="w-100 br1 shadow-inner-1 bg-light-silver">
                    <span className={`db h1 br1 ${bgColor}`} style={{width: `${progress}%`}}></span>
                  </div>
									<div className="flex justify-between mb2">
                    <div className='mt2 mb1'><span className="hk-label">Started On:</span>{`${startedOn}`}</div>
                    <div className='mt2 mb1'><span className="hk-label">End On:</span>{`${finishedOn}`}</div>
										<div className='mt2 mb1'><span className="hk-label">Runtime:</span>{`${runtime}`}</div>
                  </div>
									<div className="flex justify-between mb2">
									{!job.finishedOn &&
									<Button
										color="primary"
										startIcon={<ClearIcon />}
										onClick={ () => this.cancelJob(job.key) }
									>Cancel</Button>}
									</div>
								</Grid>
              )
            })}
	      </Grid>
			</this._css>
			</Paper>
    );
  }


	styleComponent = (siteManager) =>
	{
		return StyledComponent.div`

			min-height: 80%;
			overflow-y: scroll;
			padding: 15px 15px;
			@media(min-width: 600px)
			{
				min-width: 500px;
			}

			.main-btn-section, .bottom-btn-section
			{
				margin-bottom: 25px;
				margin-top: 8px;
				justify-content: space-between;
		    display: flex;
			}
			.bottom-btn-section
			{
				margin-bottom: 0px;
				margin-top: 50px;
				justify-content: space-between;
		    display: flex;
			}
			.schema-name
			{
				width: 100%;
				margin-top: 10px;
			}
			.schema-list
			{
				margin-top: 25px;
			}
			.table-properties-btn
			{
				margin-top: 15px;
			}
			.admin-table-properties
			{
				margin-top: 15px;
				width: 100%;
			}
			.table-properties
			{
				margin-top: 15px;
				width: 100%;
				font-size: 10px;

				textarea
				{
					white-space: pre-line;
				}
			}
			.schema-btns
			{
				margin-bottom: 10px;
			}
			.schema-form
			{
				margin-top: 15px;
			}
			.model-list
			{
				margin-top: 15px;

				h1
				{
					text-align: left;
				}
				h2
				{
					text-align: left;
					color: black;
				}
			}
			.MuiChip-outlinedPrimary
			{
				width: 100%;
				margin-top: 10px;
			}

			.job-description
			{
				color: black;
				text-align: left;
				margin: auto;
			}

			.checkbox-label
			{
				color: #3f51b5;
			}

			.select-control
			{
				width: 100%

				@media(min-width: 600px)
				{
					width: 25%;
				}
			}

			nav
			{
				display: flex !important;
				padding: 16px !important;
			}
		`;
	}
}

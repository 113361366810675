import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CsvButton from './CsvButton';
import DeleteButton from './DeleteButton';
import StyledComponent from 'styled-components';


class EnhancedTableToolbar extends React.Component
{
  // MARK: - Data fields
  _css = null;

  // MARK: - Constructor
  constructor(props)
  {
    super(props);

    this.state = {};

    this._css = this.styleComponent(props.siteManager);
  }


  // MARK: - Table protocol
  tableDidFinishLoading = (action, message, error) =>
  {
    this.props.tableDidFinishLoading(action, message, error);
  }

  // MARK: - Render
  shouldComponentUpdate(nextProps, nextState)
  {
    // If style manager provided now, update css
    if(nextProps.siteManager !== null && this.props.siteManager === null)
    {
      this._css = this.styleComponent(nextProps.siteManager);
    }
    return (this.props.selected !== nextProps.selected ||
            this.props.isCreateAvailable !== nextProps.isCreateAvailable ||
              this.props.isUpdateAvailable !== nextProps.isUpdateAvailable ||
                this.props.siteManager !== nextProps.siteManager);
  }

  render()
  {
    let { selected } = this.props;
    return (
      <this._css>
        <Toolbar className={`table-header ${selected.length > 0 ? 'table-header-selected' : ''}`}>

          {selected.length > 0 ? (
            <Typography className='table-header-title' color="inherit" variant="subtitle1">
              {selected.length} selected
            </Typography>
          ) : (
            <Typography className='table-header-title' variant="h6" id="tableTitle">
              {/*this.props.title*/}
            </Typography>
          )}

           {selected.length > 0 && this.props.isCsvAvailable &&
           <CsvButton csvData={this.props.getTableDataForSelectedRows({isForCsv: true})} fileName='csvexport' />}

           {selected.length > 0 && this.props.isDeleteAvailable &&
           <DeleteButton data={selected} cookies={this.props.cookies}
              tableDidStartLoading={this.props.tableDidStartLoading}
              tableDidFinishLoading={(action, message, error) => this.tableDidFinishLoading(action, message, error)}
              model={this.props.model}
           />}

           {selected.length === 1 && this.props.isUpdateAvailable &&
             <Tooltip title="Update selected rows">
               <IconButton onClick={this.props.updateButtonOnClick} aria-label="row">
                 <EditIcon />
               </IconButton>
             </Tooltip>}

           {selected.length === 0 && this.props.isCreateAvailable &&
             <Tooltip title="Create new record">
               <IconButton onClick={this.props.createButtonOnClick} aria-label="row">
                 <AddIcon />
               </IconButton>
             </Tooltip>}

            {selected.length === 1 && this.props.customButton1 &&
              this.props.customButton1(selected)
            }
        </Toolbar>
      </this._css>
    );
  }

  styleComponent = (siteManager) =>
  {
    var headerBg = '#FFE2EC';
    var headerText = '#F50057';

    if(siteManager !== null)
    {
      headerBg = siteManager.getColorFor('Table', 'Header Background (Row Selected)');
      headerText = siteManager.getColorFor('Table', 'Header Text (Row Selected)');
    }
    return StyledComponent.div`
      .table-header-selected
      {
        color: ${headerText};
        background-color: ${headerBg};
      }
      .table-header
      {
        padding-left: 16px;
        padding-right: 8px;
      }
      .table-header-title
      {
        flex: 1;
      }
    `;
  }
}

export default EnhancedTableToolbar;

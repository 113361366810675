'use strict';

export default
{
  form:
  {
    formInputLabel:
    {
      color: '#235373'
    },
    popupDialog:
    {
      question:
      {
        color: '#575757'
      },
      okayBtn:
      {
        color: '#235373'
      }
    },
    submitBtn:
    {
      color: '#70a040'
    }
  },
  posts:
  {
    create:
    {
      title:
      {
        color: '#235373'
      },
      backgroundAccent:
      {
        color: '#E47725'
      }
    },
    post:
    {
      actions:
      {
        background:
        {
          color: '#f1f1f2'
        },
        like:
        {
          inactive:
          {
            primary:
            {
              color: '#225373'
            },
            secondary:
            {
              color:'#FFFFFF'
            }
          },
          active:
          {
            primary:
            {
              color: '#70a040'
            },
            secondary:
            {
              color:'#FFFFFF'
            }
          }
        }
      },
      comments:
      {
        sendButton:
        {
          background:
          {
            color: '#225373'
          },
          text:
          {
            color: '#FFFFFF'
          }
        }
      }
    }
  },
  settings:
  {
    closeButton:
    {
      color: '#9e9e9e'
    },
    toggleButton:
    {
      color: '#70a040'
    },
    checkbox:
    {
      color: '#70a040'
    }
  }
};

import ApiRequest  from '../api/request.js';

export default class Component
{
  /**
    Interact with component routes
    @note Parameters come in an object named params
    @param  {String}  params.action   Is the component to initialize (posts|nav))
    @param  {String}  apiToken  Token so we can talk to backend
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Interact(params, apiToken)
  {
    console.log('\t\tComponent.Interact()');
    try
    {
      let response = await ApiRequest.sendRequest("post", {}, "component/" + params.action, apiToken);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }
}

import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import ApiRequest    from '../../api/request.js';
import { Confirm } from "../Confirmation";

class DeleteButton extends React.Component
{
  render()
  {
  return (
      <Tooltip title="Delete selected rows">
        <IconButton onClick={async () =>
          {
            console.log('DeleteButton.onClick()');
            let message = 'Are you sure you want to delete the selected record(s)?';
        		let confirmed = await Confirm(message);
        		if(confirmed)
        		{
        			this.props.tableDidStartLoading();

              var action =
              {
                  type: 'delete',
                  data: null
              };

        			let params = { model: this.props.model, id: this.props.data};
        			try
        			{
        				let response = await ApiRequest.sendRequest("post", params, "data/delete", this.props.cookies.get('token'));
                this.props.tableDidFinishLoading(action, response.data.message, response.data.error);
        			}
        			catch(err)
        			{
                console.log("DeleteButton err:" + err + "\nStack:\n" + err.stack);
                this.props.tableDidFinishLoading(action, null, 'An error has occurred, please try again or contact support.');
        			}
            }
          }} aria-label="row">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      );
  }
}


export default DeleteButton;
